<!--
 * @Description: 客户审批页面
 * @Author: ChenXueLin
 * @Date: 2021-10-21 11:07:11
 * @LastEditTime: 2021-10-21 15:12:51
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <div class="detailInfo-content">
            <!-- 基本信息 start -->
            <div class="baseInfo-box">
              <div class="edit-title">开票信息</div>
              <el-form
                label-width="140px"
                label-position="right"
                :inline="true"
              >
                <el-form-item label="企业类型">
                  1111
                </el-form-item>
                <el-form-item label="开票名称">
                  222
                </el-form-item>
                <el-form-item label="备注">
                  444
                </el-form-item>
              </el-form>
            </div>
            <!-- 基本信息 end -->
            <!-- 客户基础信息 start -->
            <div class="baseInfo-box">
              <div class="edit-title">客户基础信息</div>
              <el-form label-width="140px" :inline="true">
                <el-form-item label="客户名称">
                  5555
                </el-form-item>
                <el-form-item label="客户属性">
                  666
                </el-form-item>

                <el-form-item label="客户来源">
                  7777
                </el-form-item>
                <el-form-item label="所属主体">
                  7788
                </el-form-item>
                <el-form-item label="注册资本">
                  888
                </el-form-item>
                <el-form-item label="业务员">
                  99999
                </el-form-item>
                <el-form-item label="客户地址">
                  热热热
                </el-form-item>
              </el-form>
            </div>
            <!--客户基础信息 end -->
            <!-- 客户联系人 start -->
            <div class="baseInfo-box">
              <div class="edit-title">客户联系人</div>
              <customer-contact></customer-contact>
            </div>
            <!-- 客户联系人 end -->
          </div>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel">取消</el-button>
          <el-button type="primary">确定</el-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";

import CustomerContact from "../components/customerContact.vue";

export default {
  name: "approval",
  components: { CustomerContact },
  data() {
    return {
      searchForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ]
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped>
.baseInfo-box {
  .el-input {
    width: 220px;
  }
  .el-select {
    width: 220px;
  }
}
</style>
